define("discourse/plugins/discourse-post-voting/discourse/components/post-voting-comment-composer", ["exports", "@ember/component", "@glimmer/component", "@glimmer/tracking", "@ember/object", "@ember/service", "discourse-i18n", "@ember/template-factory"], function (_exports, _component, _component2, _tracking, _object, _service, _discourseI18n, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <div class="post-voting-comment-composer">
    <textarea
      class="post-voting-comment-composer-textarea"
      value={{this.value}}
      {{on "input" this.onInput}}
      {{on "keydown" @onKeyDown}}
    ></textarea>
  
    {{#if this.value.length}}
      {{#if this.errorMessage}}
        <div class="post-voting-comment-composer-flash error">
          {{this.errorMessage}}
        </div>
      {{else}}
        <div class="post-voting-comment-composer-flash">
          {{i18n
            "post_voting.post.post_voting_comment.composer.length_ok"
            count=this.remainingCharacters
          }}
        </div>
      {{/if}}
    {{/if}}
  </div>
  */
  {
    "id": "EZXDaaxB",
    "block": "[[[10,0],[14,0,\"post-voting-comment-composer\"],[12],[1,\"\\n  \"],[11,\"textarea\"],[24,0,\"post-voting-comment-composer-textarea\"],[16,2,[30,0,[\"value\"]]],[4,[38,2],[\"input\",[30,0,[\"onInput\"]]],null],[4,[38,2],[\"keydown\",[30,1]],null],[12],[13],[1,\"\\n\\n\"],[41,[30,0,[\"value\",\"length\"]],[[[41,[30,0,[\"errorMessage\"]],[[[1,\"      \"],[10,0],[14,0,\"post-voting-comment-composer-flash error\"],[12],[1,\"\\n        \"],[1,[30,0,[\"errorMessage\"]]],[1,\"\\n      \"],[13],[1,\"\\n\"]],[]],[[[1,\"      \"],[10,0],[14,0,\"post-voting-comment-composer-flash\"],[12],[1,\"\\n        \"],[1,[28,[35,4],[\"post_voting.post.post_voting_comment.composer.length_ok\"],[[\"count\"],[[30,0,[\"remainingCharacters\"]]]]]],[1,\"\\n      \"],[13],[1,\"\\n\"]],[]]]],[]],null],[13]],[\"@onKeyDown\"],false,[\"div\",\"textarea\",\"on\",\"if\",\"i18n\"]]",
    "moduleName": "discourse/plugins/discourse-post-voting/discourse/components/post-voting-comment-composer.hbs",
    "isStrictMode": false
  });
  class PostVotingCommentComposer extends _component2.default {
    static #_ = (() => dt7948.g(this.prototype, "siteSettings", [_service.service]))();
    #siteSettings = (() => (dt7948.i(this, "siteSettings"), void 0))();
    static #_2 = (() => dt7948.g(this.prototype, "value", [_tracking.tracked], function () {
      return this.args.raw ?? "";
    }))();
    #value = (() => (dt7948.i(this, "value"), void 0))();
    onInput(event) {
      this.value = event.target.value;
      this.args.onInput?.(event.target.value);
    }
    static #_3 = (() => dt7948.n(this.prototype, "onInput", [_object.action]))();
    get errorMessage() {
      if (this.value.length < this.siteSettings.min_post_length) {
        return (0, _discourseI18n.i18n)("post_voting.post.post_voting_comment.composer.too_short", {
          count: this.siteSettings.min_post_length
        });
      }
      if (this.value.length > this.siteSettings.post_voting_comment_max_raw_length) {
        return (0, _discourseI18n.i18n)("post_voting.post.post_voting_comment.composer.too_long", {
          count: this.siteSettings.post_voting_comment_max_raw_length
        });
      }
    }
    get remainingCharacters() {
      return this.siteSettings.post_voting_comment_max_raw_length - this.value.length;
    }
  }
  _exports.default = PostVotingCommentComposer;
  (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, PostVotingCommentComposer);
});